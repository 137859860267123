@use '../../styles/mixins' as mixins;

.features-module {
    
    text-align: center;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    img {
        max-width: 40px;
        display: block;
        margin: 0 auto var(--wp--preset--spacing--40);
    }

    h3 {
        font-size: var(--wp--preset--font-size--heading-xs);
        color: var(--wp--preset--color--bnpp-green);
    }

    p:not(.learn-more) {
        color: var(--wp--preset--color--bnpp-black);
        text-wrap: balance;
    }

    .learn-more {
        color: var(--wp--preset--color--bnpp-green);
        position: relative;
        width: fit-content;
        margin: 0 auto;

        &::before {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            top: 100%;
            background-color: var(--wp--preset--color--bnpp-green);
        }

        &::after {
            content: "\2192";
            font-family: system-ui;
            line-height: 1;
            padding-left: 5px;
        }
    }

    &.dark {

        h3 {
            color: #7FD4B5!important;
        }

        .learn-more {
            color: #7FD4B5;

            &::before {
                background-color: #7FD4B5;
            }
        }
        
    }

}

a.features-module {
    text-decoration: none;
}